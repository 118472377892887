.gallery-img {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  width: 100px;
  .action-img {
    position: absolute;
    top: 0;
    width: 100px;
    background: #0000004a;
    aspect-ratio: 5/7;
    object-fit: cover;
    text-align: center;
    display: none;
    .delete-icon {
      color: #b80707;
      font-size: 19px;
    }
  }
  &:hover {
    .action-img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.test {
  display: inline-block;
  margin-right: 20px;
  width: 100px;
  height: 100px;
}
.product-modal {
  .ant-modal-title {
    font-weight: 600;
  }
  .ant-modal-content {
    min-height: 100vh;
    border-radius: 0;
    position: relative;
    .ant-modal-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #fff;
    }
  }
}

.group-modal {
  .category-group {
    padding: 8px;
    background: #8080801a;
    border-radius: 7px;
    margin-bottom: 10px;
    font-size: 18px;
    min-width: 200px;
    text-align: center;
    position: relative;
    margin-right: 10px;
    border: 1px solid #8080804f;
    input{
      padding: 5px 10px;
    }
    .action{
      position: absolute;
      top: -17px;
      right: 0;
    }
  }
}
