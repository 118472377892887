.sing-up-page {
  .right-main-content {
    background-size: cover !important;
    .card {
      box-shadow: none;
      min-height: auto;
      margin-top: 100px !important;
    }
  }
}
