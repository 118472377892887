body {
  font-family: "Poppins", sans-serif;
  line-height: 16px;
}

.left-menu li a.active {
  background-color: #ffff;
  font-weight: 700;
}
.ant-pagination {
  li {
    margin: 0;
    &:hover {
      border-color: #212529;
    }
    button {
      &:hover {
        border-color: #212529;
      }
    }
  }
  .ant-pagination-item-active {
    background-color: #212529;
    border-color: #212529;
    color: #fff;
  }
}

.anticon {
  vertical-align: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  hr {
    display: block;
    flex: 1;
    margin: 0px 30px;
    height: 1px;
    background: #ddd;
    &.left {
      margin-left: 0px;
    }

    &.right {
      margin-right: 0px;
    }
  }
}

.form-control-select {
  .ant-select-selector {
    min-height: 46px;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
  }
}

.left-menu {
  .accordion > .card {
    overflow: hidden;
    border-radius: 11px;
    padding: 0 !important;
    box-shadow: none;
    border: 0;
    width: 100%;
    margin-bottom: 0;
  }
}

.left-logo {
  text-align: center;
  svg {
    width: 115px;
    height: 60px;
    &:hover path {
      fill: #fff;
      transition: all 0.4s ease-in-out;
    }
  }
}

.ql-editor {
  min-height: 200px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}
.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-tooltip-inner {
  background: rgba(0, 0, 0, 0.5);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  vertical-align: 2px;
}
.ant-card-head-title {
  font-weight: 600;
  font-size: 20px;
}

.custom-positioning-modal {
  top: 0;
  max-width: 100% !important;
  margin: 0;
  width: 100vw !important;
  height: 100vh;
  .ant-modal-content {
    min-height: 100vh;
  }
  .ant-modal-close {
    display: none;
    // top: 10px;
    // right: 10px;
    // color: #fff;
    // border: 2px solid #fff;
    // border-radius: 50%;
    // padding: 3px 5px 5px;
    // .ant-modal-close-x {
    //   line-height: unset;
    //   height: auto;
    //   width: auto;
    // }
  }
}
.empty-text {
  font-size: 15px;
  font-style: italic;
  color: cadetblue;
}
.disable-div {
  pointer-events: none;
  opacity: 0.4;
}
i {
  cursor: pointer;
}

.ant-btn-primary[disabled] {
  color: #fff;
  background-color: #21252994 !important;
  &:hover, &:focus {
    background-color: #21252994 !important;
  }
}
.ant-table-thead > tr > th {
  font-weight: 700;
}

.ant-btn-primary {
  background-color: #212529d1;
  // border-color: #212529;
  &:hover, &:focus {
    background-color: #212529 !important;
  }
}

.content-p-text{
  p{
    margin-bottom: 0em;
  }
}

.upload-picture{
  .ant-upload{
    height: 100% !important;
    border: 1px dotted #2d2d2d;
  }
}

.upload-product-image{
  color: transparent;
}
.upload-product-image::-webkit-file-upload-button {
  visibility: hidden;
}
.upload-product-image::before {
  content: '+ Upload';
  color: black;
  display: inline-block;
  background: none;
  border: 1px dotted #999;
  padding: 60px 25px;
  outline: none;
  white-space: nowrap; 
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.upload-product-image:hover::before {
  border-color: black;
}
.upload-product-image:active {
  outline: 0;
}
.upload-product-image:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}

.ribbon {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #BD1550;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  color: #fff;
}