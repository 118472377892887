@import "./base";
@import "./generic.scss";

// COMPONENTS
// @import "./components/template";
@import "./components/auth";
@import "./components/subcategory";
@import "./components/landingPage";

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }
  
  .row-dragging td {
    padding: 16px;
  }
  
  .row-dragging .drag-visible {
    visibility: visible;
  }